import React from 'react'
import Layout from '../../components/Layout'
import Hero from '../../components/Hero'
import SEO from "../../components/seo"

export default () => (
     
  <Layout>

    <SEO title="Spurt og Svarað"
        description="" 
        pathname="/faq/"
    />
     
    
    <Hero 
         headerText="Spurt og Svarað" 
         subHeaderText="Helstu spurningar og svör við þeim"
         /> 

    <section className="section column is-10 is-offset-1 content-pages">
      <div className="container">
        <div className="content intro">

            <h2>Á hvaða vef mun þjónustan vera í boði</h2>
            <p>Sett verður upp nýtt vefsvæði eingöngu fyrir þessa þjónustu sem mun bera nafnið ferdalandid.is.</p>

            <h2>Hver er aðkoma stjórnavalda að verkefninu</h2>
            <p>Eins og er þá er þetta framtak ekki á vegum hins opinbera. Leitað hefur verið eftir stuðningi við verkefnið.</p>

            <h2>Verður markaðstorgið rekið í hagnaðarskyni?</h2>
            <p>Nei, ekkert gjald er tekið fyrir að vera inni á vefnum né tekið gjald fyrir sölu. Allar sölur fara beint til birgja og standa þeir sjálfur undir þeim kostnaði óbreytt sem og ef bókun yrði gerð beint við þá.</p>

            <h2>Hvernig fara sölur fram?</h2>
            <p>Hægt verður að bóka ferðir og afþreyingu beint á vefnum, fyrirtæki sem taka þátt setja upp sérstaka bókunarrás fyrir vefinn og nýtir vefurinn sérstaka sölukubba fyrir þær rásir.</p>

            <h2>Hvernig er efni stjórnað?</h2>
            <p>Hvert fyrirtæki ber ábyrgð á eigin efni og innihaldi. Efni kemur beint úr Bókun og verður birt óbreytt á markaðstorginu.</p>

            <h2>Hvað ef fyrirtæki er ekki í Bókun?</h2>
            <p>Til að geta verið með vörur til sölu þá þarf birgðakerfi og mælt er með Bókun enda öflugt og öruggt kerfi. Það er enginn kostnaður sem fylgir notkun á Bókun (fyrir utan söluþóknun) og geta allir skráð sig.</p>

        </div>

       
      </div>
    
    </section>

  </Layout>
)
